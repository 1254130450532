import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "manualOption",
    "automaticOptions",
    "geofenceField",
    "carrierField",
  ];

  connect() {
    const geofence = document.getElementById('delivery_option_geofence').checked;
    const carrier = document.getElementById('delivery_option_carrier').checked;
    if (geofence) {
      this.toggleFields("geofence");
    } else if(carrier) {
      this.toggleFields("carrier_time_threshold");
    }
  }

  selectManual() {
    this.manualOptionTarget.checked = true;

    document.getElementById("delivery_option_geofence").checked = false;
    document.getElementById("delivery_option_carrier").checked = false;
    document.getElementById('automatic_enabled_default').value = '0';
    // document.querySelector("[name='delivery_options[automatic][enabled]']").value = "0";

    this.hideAutomaticFields();
  }

  selectAutomatic(event) {
    this.manualOptionTarget.checked = false;

    document.getElementById('automatic_enabled_default').value = '1';

    this.toggleFields(event.target.value);
  }

  toggleFields(selectedType = null) {
    const geofenceField = document.getElementById("geofence-radius-field");
    const carrierField = document.getElementById("carrier-time-threshold-field");
  
    geofenceField.style.display = selectedType === "geofence" ? "block" : "none";
    carrierField.style.display = selectedType === "carrier_time_threshold" ? "block" : "none";
  
    if (selectedType !== "geofence") {
      geofenceField.value = "";
    }
  
    if (selectedType !== "carrier_time_threshold") {
      carrierField.value = "";
    }
  }
  
  hideAutomaticFields() {
    this.toggleFields();
  }
}
